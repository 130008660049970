<template>
    <div>
      <v-bottom-navigation
        color="info"
        horizontal
        :background-color="colorfrist"
        dark
        height="40px"
      >
     
        <v-btn to="/vecprovince/sedirector_app" class="mr-5">
          <span>ข้อมูลผู้สมัคร</span>
          <v-icon>mdi-numeric-1-box</v-icon>
        </v-btn>
  
       
  
        <v-btn to="/vecprovince/sedirector_app_b" class="mr-5">
          <span>รายงาน ภาค ข</span>
          <v-icon>mdi-numeric-2-box</v-icon>
        </v-btn>
      </v-bottom-navigation>
  
   
    </div>
  </template>
  
  <script>
  export default {
    name: "HRvecPersonnelmenu",
  
    data() {
      return {
        personnels: false
      };
    },
  
    mounted() {},
  
    methods: {
      async personnelselect() {
        this.personnels = true;
      }
    },
    computed: {
      colorfrist() {
        return "#414141";
      },
      colorsecond() {
        return "#548858";
      },
    }
  };
  </script>
  
  <style lang="scss" scoped></style>
  